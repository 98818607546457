import Colors from '../../../themes/colors'

import { openWhatsappSales } from '../../../services/utils/helpers/createWhatsappLink'

import RoundedSecondaryButton from '../../Buttons/RoundedSecondaryButton'

export default function DoubtsSection () {
  return (
    <div
      id='duvidas'
      style={{
        backgroundColor: Colors.lightBlue,
        paddingTop: '8vh',
        paddingBottom: '8vh',
        paddingRight: 16,
        paddingLeft: 16
      }}
    >
      <h2 className='h2'>Ficou alguma dúvida? Tudo bem!</h2>
      <div
        style={{
          marginTop: '5vh',
          marginBottom: '5vh',
          paddingRight: 32,
          paddingLeft: 32
        }}
      >
        <p className='p'>
          Clique no botão abaixo que te <b>ajudaremos</b> a esclarecer:
        </p>
      </div>
      <div style={{ maxWidth: 360, marginLeft: 'auto', marginRight: 'auto' }}>
        <RoundedSecondaryButton
          buttonId='whatsapp-button-doubts'
          buttonText={'Tenho Dúvidas'}
          onClick={() => openWhatsappSales()}
        />
      </div>
    </div>
  )
}
