import * as React from 'react'
import PropTypes from 'prop-types'
import { HashLink } from 'react-router-hash-link'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import { ReactComponent as MainLogo } from '../assets/images/logos/full-name-blue-orange.svg'
import Colors from '../themes/colors'

const styles = {
  logo: {
    width: 120,
    height: '100%'
  },
  menuIcon: {
    color: '#053F5C'
  }
}

const drawerWidth = 240
const navItems = [
  {
    label: 'Início',
    anchor: '/#inicio',
    id: 'nav-button-start',
    idMobile: 'nav-button-start-mobile'
  },
  {
    label: 'Funcionalidades',
    anchor: '/#funcionalidades',
    id: 'nav-button-features',
    idMobile: 'nav-button-features-mobile'
  },
  {
    label: 'Sugestões',
    anchor: '/#sugestoes',
    id: 'nav-button-suggestions',
    idMobile: 'nav-button-suggestions-mobile'
  },
  {
    label: 'Planos',
    anchor: '/#planos',
    id: 'nav-button-plans',
    idMobile: 'nav-button-plans-mobile'
  },
  {
    label: 'Dúvidas',
    anchor: '/#duvidas',
    id: 'nav-button-doubts',
    idMobile: 'nav-button-doubts-mobile'
  },
  {
    label: 'Aplicativos',
    anchor: '/#aplicativos',
    id: 'nav-button-apps',
    idMobile: 'nav-button-apps-mobile'
  }
]

function NavBar (props) {
  const { window, scrollWithOffset } = props

  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(prevState => !prevState)
  }

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        textAlign: 'center',
        backgroundColor: Colors.lightOrange,
        height: '100%'
      }}
    >
      <Typography variant='h6' sx={{ my: 2 }}>
        <MainLogo style={styles.logo} />
      </Typography>
      <Divider />
      <List>
        {navItems.map(item => (
          <ListItem key={item.id} disablePadding>
            <HashLink
              to={item.anchor}
              smooth
              scroll={el => scrollWithOffset(el)}
              style={{ textDecoration: 'none' }}
            >
              <ListItemButton
                sx={{
                  textAlign: 'center',
                  color: Colors.darkBlue,
                  marginBottom: 1
                }}
              >
                <p id={item.idMobile} style={{ letterSpacing: 1 }}>
                  {item.label}
                </p>
                {/* <ListItemText 
                id={item.id}
                primary={item.label} /> */}
              </ListItemButton>
            </HashLink>
          </ListItem>
        ))}
      </List>
    </Box>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component='nav' sx={{ backgroundColor: '#FFDEBC' }}>
        <Toolbar>
          <Typography
            variant='h6'
            component='div'
            sx={{
              flexGrow: 1,
              display: { xs: 'block', sm: 'block' },
              padding: 1.5
            }}
          >
            <HashLink
              to='/#inicio'
              smooth
              scroll={el => scrollWithOffset(el)}
              style={{ textDecoration: 'none' }}
            >
              <MainLogo style={styles.logo} />
            </HashLink>
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map(item => (
              <HashLink
                key={item.id}
                to={item.anchor}
                smooth
                scroll={el => scrollWithOffset(el)}
                style={{ textDecoration: 'none' }}
              >
                <Button
                  id={item.id}
                  sx={{
                    color: '#053F5C',
                    textTransform: 'none',
                    letterSpacing: 1
                  }}
                >
                  {item.label}
                </Button>
              </HashLink>
            ))}
          </Box>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon id='nav-bar-mobile-menu-icon' style={styles.menuIcon} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box component='nav'>
        <Drawer
          container={container}
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth
            }
          }}
          anchor={'right'}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  )
}

NavBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
}

export default NavBar
