import Colors from '../../../themes/colors'
import { ReactComponent as MainLogo } from '../../../assets/images/logos/full-name-blue-orange.svg'

const styles = {
  logo: {
    width: 120,
    height: '100%'
  }
}

export default function FooterSection () {
  return (
    <div
      style={{
        backgroundColor: Colors.lighterOrange,
        paddingTop: '4vh',
        paddingBottom: '4vh',
        paddingRight: 16,
        paddingLeft: 16,
        boxShadow: '1px 1px 10px 1px grey',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
      }}
    >
      <div style={{ marginBottom: 16 }}>
        <MainLogo style={styles.logo} />
      </div>
      <div style={{ marginBottom: 12 }}>
        <p className='p'>(16) 93618-4347</p>
      </div>
      <div>
        <p className='p'>contato@gepety.com.br</p>
      </div>
    </div>
  )
}
