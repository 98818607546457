import Colors from '../../themes/colors'

const styles = {
  buttonStyle: {
    backgroundColor: Colors.darkBlue,
    borderRadius: 25,
    padding: 12,
    paddingRight: 18,
    paddingLeft: 18,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  buttonTextStyle: {
    fontSize: 16,
    color: Colors.white,
    textAlign: 'center',
    letterSpacing: 2
  }
}

export default function RoundedPrimaryButton ({
  extraStyle,
  buttonText,
  onClick,
  isLoading,
  buttonTextStyle,
  buttonId
}) {
  return (
    <div style={styles.buttonStyle} onClick={() => !isLoading && onClick()}>
      {isLoading ? (
        <div />
      ) : (
        <p id={buttonId} style={styles.buttonTextStyle}>
          {buttonText}
        </p>
      )}
    </div>
  )
}
