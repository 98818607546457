import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import ScrollToTop from './components/ScrollToTop'

import Home from './containers/Home'
import Plans from './containers/Plans'
import PopupTestPage from './containers/PopupTestPage'

export default function Router (props) {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route>
          <Route path='/planos' element={<Plans />} />
          <Route path='/sobre' element={<PopupTestPage />} />
          <Route path='/' element={<Home />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
