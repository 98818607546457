import * as React from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: 500,
  bgcolor: 'background.paper',
  borderRadius: 8,
  boxShadow: 24,
  p: 4,
  outline: 'none'
}

export default function BasicModal ({ isOpen, closeModal, ...props }) {
  return (
    <div>
      <Modal
        open={isOpen}
        onClose={() => {}}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        style={{ backdropFilter: 'blur(8px)' }}
      >
        <Box sx={style}>{props.children}</Box>
      </Modal>
    </div>
  )
}
