import PlanCard from '../../PlanCard'
import { openAppPage } from '../../../services/utils/helpers/openPages'

export default function PlansSection ({ customPaddingTop }) {
  return (
    <div
      id='planos'
      className='plans-div'
      style={{
        paddingTop: customPaddingTop ? customPaddingTop : '8vw',
        paddingBottom: '2vw',
        paddingRight: 16,
        paddingLeft: 16
      }}
    >
      <h2 className='h2' style={{ marginBottom: '6vh' }}>
        Planos
      </h2>
      <div
        className='flex-grid-plans'
        style={{
          flexDirection: 'row',
          flexWrap: 'wrap'
        }}
      >
        <div key={'lala'} className='col plan-container'>
          <PlanCard
            buttonId='test-button-monthly-plan'
            planTitle={'Plano Mensal'}
            planValueText={'99,90'}
            planDiscountText={''}
            onClick={() => openAppPage()}
          />
        </div>
        <div className='col plan-container'>
          <PlanCard
            buttonId='test-button-quarterly-plan'
            planTitle={'Plano Trimestral'}
            planInstallmentsText={'3x'}
            planValueText={'94,90'}
            planDiscountText={[
              'Um total de R$ 284,70 - ',
              <b key={'first'}>5% de desconto</b>,
              ' com relação ao plano mensal.'
            ]}
            onClick={() => openAppPage()}
          />
        </div>
      </div>
      <div
        className='flex-grid-plans'
        style={{
          paddingBottom: '5vw',
          flexDirection: 'row',
          flexWrap: 'wrap'
        }}
      >
        <div className='col plan-container'>
          <PlanCard
            buttonId='test-button-semiannually-plan'
            planTitle={'Plano Semestral'}
            planInstallmentsText={'6x'}
            planValueText={'79,90'}
            planDiscountText={[
              'Um total de R$ 479,40 - ',
              <b key={'second'}>20% de desconto</b>,
              ' com relação ao plano mensal.'
            ]}
            onClick={() => openAppPage()}
          />
        </div>
        <div className='col plan-container'>
          <PlanCard
            buttonId='test-button-annually-plan'
            planTitle={'Plano Anual'}
            planInstallmentsText={'12x'}
            planValueText={'69,90'}
            planDiscountText={[
              'Um total de R$ 838,80 - ',
              <b key={'third'}>30% de desconto</b>,
              ' com relação ao plano mensal.'
            ]}
            onClick={() => openAppPage()}
          />
        </div>
      </div>
    </div>
  )
}
