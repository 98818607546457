import Colors from '../../themes/colors'

import RoundedPrimaryButton from '../Buttons/RoundedPrimaryButton'

const styles = {
  cardStyle: {
    marginBottom: 8,
    paddingTop: 32,
    paddingRight: 24,
    paddingLeft: 24,
    paddingBottom: 16,
    backgroundColor: Colors.lightOrange,
    borderRadius: 32,
    width: '80%'
  },
  priceStyle: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 36,
    justifyContent: 'center',
    alignItems: 'end'
  }
}

export default function PlanCard ({
  planTitle,
  planInstallmentsText,
  planValueText,
  planDiscountText,
  onClick,
  buttonId
}) {
  return (
    <div style={styles.cardStyle}>
      <div style={{ marginBottom: 48 }}>
        <h3 className='h3' style={{ fontSize: 28 }}>
          {planTitle}
        </h3>
      </div>
      <div style={styles.priceStyle}>
        {planInstallmentsText && (
          <p
            className='pSmall'
            style={{ lineHeight: 2, marginRight: 12, fontSize: 16 }}
          >
            {planInstallmentsText}
          </p>
        )}
        <p
          className='pSmall'
          style={{ lineHeight: 2, marginRight: 12, fontSize: 16 }}
        >
          R$
        </p>
        <p
          className='pBig'
          style={{ lineHeight: 1.5, letterSpacing: 4, fontSize: 24 }}
        >
          {planValueText}
        </p>
      </div>
      <div style={{ marginBottom: 24, height: 64 }}>
        <p className='pSmaller' style={{ fontSize: 14 }}>
          {planDiscountText}
        </p>
      </div>
      <RoundedPrimaryButton
        buttonId={buttonId}
        buttonText={'Testar grátis por 15 dias'}
        onClick={() => onClick()}
      />
    </div>
  )
}
