import PlansSection from '../../components/Sections/Plans'
import FooterSection from '../../components/Sections/Footer'
import NavBar from '../../components/NavBar'
import DoubtsSection from '../../components/Sections/Doubts'

function Plans () {
  const scrollWithOffset = el => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset
    const yOffset = -69
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' })
  }
  return (
    <>
      <NavBar scrollWithOffset={scrollWithOffset} />
      <PlansSection customPaddingTop={'128px'} />
      <DoubtsSection />
      <FooterSection />
    </>
  )
}

export default Plans
