const AppConfig =
  process.env.REACT_APP_ENV === 'production'
    ? {
        supportPhoneNumber: '16936183738',
        salesPhoneNumber: '16936184347',
        appLink: 'https://www.gepety.com.br',
        clientAppLink:
          'https://play.google.com/store/apps/details?id=com.gepety.gepetycliente'
      }
    : {
        supportPhoneNumber: '16936183738',
        salesPhoneNumber: '16936184347',
        appLink: 'https://www.gepety.com.br',
        clientAppLink:
          'https://play.google.com/store/apps/details?id=com.gepety.gepetycliente'
      }

export default AppConfig
