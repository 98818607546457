import Colors from '../themes/colors'

import RoundedPrimaryButton from './Buttons/RoundedPrimaryButton'
import RoundedPrimaryInvertedButton from './Buttons/RoundedPrimaryInvertedButton'

const styles = {
  cardStyle: {
    marginBottom: 8,
    paddingTop: 32,
    paddingRight: 24,
    paddingLeft: 24,
    paddingBottom: 16,
    backgroundColor: Colors.lightOrange,
    borderRadius: 32,
    width: '80%'
  }
}

export default function AppCard ({
  title,
  firstButtonId,
  firstButtonText,
  firstButtonAction,
  secondButtonText,
  secondButtonAction,
  secondButtonId,
  isSecondButtonInactive
}) {
  return (
    <div style={styles.cardStyle}>
      <div style={{ marginBottom: 48 }}>
        <h3 className='h3' style={{ fontSize: 28 }}>
          {title}
        </h3>
      </div>
      {!secondButtonText && <div style={{ marginTop: 120 }} />}
      {firstButtonText && (
        <div>
          <RoundedPrimaryButton
            buttonId={firstButtonId}
            buttonText={firstButtonText}
            onClick={() => firstButtonAction()}
          />
        </div>
      )}
      {secondButtonText && (
        <div style={{ marginTop: 24 }}>
          <RoundedPrimaryInvertedButton
            buttonId={secondButtonId}
            isInactive={isSecondButtonInactive}
            buttonText={secondButtonText}
            onClick={() => secondButtonAction()}
          />
        </div>
      )}
    </div>
  )
}
