import './style.css'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'

import AppConfig from '../../config/appConfig'

import Images from '../../themes/images'
import Colors from '../../themes/colors'

import NavBar from '../../components/NavBar'
import RoundedPrimaryButton from '../../components/Buttons/RoundedPrimaryButton'
import RoundedSecondaryButton from '../../components/Buttons/RoundedSecondaryButton'
import AppCard from '../../components/AppCard'
import FooterSection from '../../components/Sections/Footer'
import DoubtsSection from '../../components/Sections/Doubts'
import BasicModal from '../../components/Modals/BasicModal'
import RoundedPrimaryInvertedButton from '../../components/Buttons/RoundedPrimaryInvertedButton'

function PopupTestPage () {
  const navigate = useNavigate()

  const scrollWithOffset = el => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset
    const yOffset = -69
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' })
  }
  const openAppPage = () => {
    window.open(AppConfig.appLink, '_blank')
  }
  const openClientAppPage = () => {
    window.open(AppConfig.clientAppLink, '_blank')
  }

  const [showModal, setShowModal] = useState(false)

  const [canHelpGroomer, setCanHelpGroomer] = useState(null)

  // Gets groomer data from local storage to show popup or not
  useEffect(() => {
    const groomerDataHelper = JSON.parse(
      localStorage.getItem('groomerDataHelper')
    )
    if (groomerDataHelper) {
      setShowModal(false)
      setCanHelpGroomer(true)
    } else {
      setShowModal(true)
    }
  }, [])

  // When groomer is true, sets groomer data to hide popup in future page loads
  useEffect(() => {
    if (canHelpGroomer) {
      localStorage.setItem('groomerDataHelper', JSON.stringify(canHelpGroomer))
    }
  }, [canHelpGroomer])

  const [modalStep, setModalStep] = useState(0)
  const [surveySum, setSurveySum] = useState(0)

  const renderModal = () => {
    const renderFirstStep = () => {
      return (
        <div>
          <h2 style={{ marginBottom: 24 }}>
            Você trabalha com banho e tosa de pets?
          </h2>
          <div style={{ marginBottom: 18 }}>
            <RoundedPrimaryButton
              buttonId={'survey-does-grooming'}
              buttonText={'Sim'}
              onClick={() => {
                setModalStep(1)
                setSurveySum(surveySum + 1)
              }}
            />
          </div>
          <div>
            <RoundedPrimaryInvertedButton
              buttonId={'survey-does-not-do-grooming'}
              buttonText={'Não'}
              onClick={() => navigate(-1)}
            />
          </div>
        </div>
      )
    }

    const renderSecondStep = () => {
      return (
        <div>
          <h2 style={{ marginBottom: 24 }}>
            Você sente que passa mais tempo do que o necessário combinando
            horários com clientes já fidelizados?
          </h2>
          <div style={{ marginBottom: 18 }}>
            <RoundedPrimaryButton
              buttonId={'survey-spends-more-time'}
              buttonText={'Sim'}
              onClick={() => {
                setModalStep(2)
                setSurveySum(surveySum + 1)
              }}
            />
          </div>
          <div>
            <RoundedPrimaryInvertedButton
              buttonId={'survey-does-not-spend-more-time'}
              buttonText={'Não'}
              onClick={() => setModalStep(2)}
            />
          </div>
        </div>
      )
    }

    const renderThirdStep = () => {
      return (
        <div>
          <h2 style={{ marginBottom: 24 }}>
            Você já pensou em ter um sistema de gestão para seu banho e tosa?
          </h2>
          <div style={{ marginBottom: 18 }}>
            <RoundedPrimaryButton
              buttonId={'survey-thought-about-system'}
              buttonText={'Sim'}
              onClick={() => {
                setModalStep(3)
                setSurveySum(surveySum + 1)
              }}
            />
          </div>
          <div>
            <RoundedPrimaryInvertedButton
              buttonId={'survey-did-not-think-about-system'}
              buttonText={'Não'}
              onClick={() => setModalStep(3)}
            />
          </div>
        </div>
      )
    }

    const renderFinalStep = () => {
      return (
        <div>
          {surveySum > 1 ? (
            <>
              <h2 style={{ marginBottom: 24 }}>
                Vamos ajudar você a organizar seus atendimentos!
              </h2>
              <p style={{ marginBottom: 24 }}>
                Clique no botão abaixo para entender um pouco mais das nossas
                soluções:
              </p>
              <div>
                <RoundedPrimaryButton
                  buttonId={'survey-check-solutions'}
                  buttonText={'Ver soluções'}
                  onClick={() => {
                    setShowModal(false)
                    setCanHelpGroomer(true)
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <h2 style={{ marginBottom: 24 }}>
                Acho que não podemos te ajudar no momento :(
              </h2>
              <p style={{ marginBottom: 24 }}>Como você deseja prosseguir?</p>
              <div style={{ marginBottom: 18 }}>
                <RoundedPrimaryButton
                  buttonId={'survey-continue-on-website'}
                  buttonText={'Continuar no site'}
                  onClick={() => {
                    setShowModal(false)
                    setCanHelpGroomer(true)
                  }}
                />
              </div>
              <div>
                <RoundedPrimaryInvertedButton
                  buttonId={'survey-leave-website'}
                  buttonText={'Sair'}
                  onClick={() => navigate(-1)}
                />
              </div>
            </>
          )}
        </div>
      )
    }

    return (
      <BasicModal isOpen={showModal} closeModal={() => setShowModal(false)}>
        {modalStep === 0 && renderFirstStep()}
        {modalStep === 1 && renderSecondStep()}
        {modalStep === 2 && renderThirdStep()}
        {modalStep === 3 && renderFinalStep()}
      </BasicModal>
    )
  }

  return (
    <div>
      <NavBar scrollWithOffset={scrollWithOffset} />
      <div
        id='inicio'
        style={{ backgroundColor: Colors.lightBlue, paddingTop: '8vh' }}
      />
      <div
        className='flex-grid'
        style={{
          paddingTop: '10vw',
          paddingBottom: '10vw',
          flexDirection: 'row',
          flexWrap: 'wrap',
          backgroundColor: Colors.lightBlue,
          paddingRight: 16,
          paddingLeft: 16
        }}
      >
        <div
          className='col'
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <div>
            <h1 className='h1'>
              Simples,
              <br />
              Intuitivo e
              <br />
              Prático.
            </h1>
            <br />
            <p className='pBig' style={{ paddingBottom: 0 }}>
              Foque no <b>Banho e Tosa</b>.
              <br />
              Deixe a parte chata pra gente.
            </p>
            <br />
            <HashLink
              to={'/#funcionalidades'}
              smooth
              scroll={el => scrollWithOffset(el)}
              style={{ textDecoration: 'none' }}
            >
              <RoundedPrimaryButton
                buttonId='test-button-start'
                buttonText={'Quero focar no Banho e Tosa'}
                onClick={() => {
                  // openAppPage()
                }}
              />
            </HashLink>
          </div>
        </div>
        <div
          className='col example-image-div'
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <img className='example-image' src={Images.exampleImage} />
        </div>
      </div>
      <div
        id='funcionalidades'
        className='features-div'
        style={{
          paddingTop: '8vw',
          paddingBottom: '5vw',
          paddingRight: 16,
          paddingLeft: 16
        }}
      >
        <h2 className='h2'>
          As principais ferramentas para agilizar seu dia a dia.
        </h2>
        <div
          className='flex-grid-features'
          style={{
            paddingTop: '5vw',
            marginBottom: 32,
            flexDirection: 'row',
            flexWrap: 'wrap'
          }}
        >
          <div className='col single-feature-container'>
            <div className='feature-icon'>
              <img className='feature-icon' src={Images.simpleScheduleIcon} />
            </div>
            <div style={{ marginBottom: 8, minHeight: '9.4vh' }}>
              <h3 className='h3'>Agendamento Simplificado</h3>
            </div>
            <p className='p'>
              Faça um agendamento em <b>segundos</b>.
              <br />
              Chega de ficar procurando um horário disponível na sua agenda.
            </p>
          </div>
          <div className='col single-feature-container'>
            <div className='feature-icon'>
              <img className='feature-icon' src={Images.shareScheduleIcon} />
            </div>
            <div style={{ marginBottom: 8, minHeight: '9.4vh' }}>
              <h3 className='h3'>Compartilhamento de Agenda</h3>
            </div>
            <p className='p'>
              Chega de ficar trocando várias mensagens até conseguir marcar com
              o cliente.
              <br />
              <b>Calculamos todos seus horários disponíveis</b>. O cliente
              escolhe e pronto, agendamento realizado.
            </p>
          </div>
          <div className='col single-feature-container'>
            <div className='feature-icon'>
              <img className='feature-icon' src={Images.contactsIcon} />
            </div>
            <div style={{ marginBottom: 8, minHeight: '9.4vh' }}>
              <h3 className='h3'>Controle de Clientes</h3>
            </div>
            <p className='p'>
              Tenha as <b>principais informações</b> dos seus clientes em um só
              lugar, de maneira <b>organizada</b>.
              <br />
              Informações de contato, endereço e quais serviços realizou.
            </p>
          </div>
        </div>
        <div
          className='flex-grid-features'
          style={{
            paddingBottom: '5vw',
            flexDirection: 'row',
            flexWrap: 'wrap'
          }}
        >
          <div className='col single-feature-container'>
            <div className='feature-icon'>
              <img className='feature-icon' src={Images.clientAppIcon} />
            </div>
            <div style={{ marginBottom: 8, minHeight: '9.4vh' }}>
              <h3 className='h3'>App do Cliente*</h3>
            </div>
            <p className='p'>
              Seu cliente tem o <b>próprio app</b>, com acesso a serviços
              realizados, próximos serviços e podendo até{' '}
              <b>fazer o agendamento</b> sem precisar falar com você!
            </p>
            <div style={{ marginTop: 8 }}>
              <p className='pSmall'>
                *No momento somente Android. Em breve também iOS.
              </p>
            </div>
          </div>
          <div className='col single-feature-container'>
            <div className='feature-icon'>
              <img className='feature-icon' src={Images.whatsappIcon} />
            </div>
            <div style={{ marginBottom: 8, minHeight: '9.4vh' }}>
              <h3 className='h3'>Suporte via WhatsApp</h3>
            </div>
            <p className='p'>
              Precisou de alguma coisa? Estamos aqui para te <b>ajudar</b>.
            </p>
          </div>
          <div className='col single-feature-container'>
            <div className='feature-icon'>
              <img className='feature-icon' src={Images.financialControlIcon} />
            </div>
            <div style={{ marginBottom: 8, minHeight: '9.4vh' }}>
              <h3 className='h3'>Controle Financeiro</h3>
              <p className='pBigCenter'>(em breve)</p>
            </div>
            <p className='p'>
              Controle pagamentos recebidos e despesas.
              <br />
              Tenha informações <b>diretas</b> e <b>resumidas</b> sobre seu{' '}
              <b>resultado financeiro</b>.
            </p>
          </div>
        </div>
        <div style={{ maxWidth: 360, marginLeft: 'auto', marginRight: 'auto' }}>
          <RoundedPrimaryButton
            buttonId='test-button-features'
            buttonText={'Quero focar nos Pets!'}
            onClick={() => openAppPage()}
          />
        </div>
      </div>
      <div
        id='sugestoes'
        style={{
          backgroundColor: Colors.lightBlue,
          paddingTop: '8vh',
          paddingBottom: '8vh',
          paddingRight: 16,
          paddingLeft: 16
        }}
      >
        <h2 className='h2'>Faltou alguma ferramenta?</h2>
        <div
          style={{
            marginTop: '5vh',
            marginBottom: '5vh',
            paddingRight: 32,
            paddingLeft: 32
          }}
        >
          <p className='p'>
            Nosso sistema é <b>constantemente aprimorado</b> com a ajuda da
            nossa <b>comunidade</b>.
            <br />
            <br />
            Queremos entregar o <b>melhor</b> para vocês.
            <br />
            <br />
            Se tiverem alguma sugestão de ferramenta que vai facilitar ainda
            mais suas tarefas, estamos <b>muito</b> interessados em ouvir!
          </p>
        </div>
        <div style={{ maxWidth: 360, marginLeft: 'auto', marginRight: 'auto' }}>
          <RoundedSecondaryButton
            buttonId='test-button-suggestions'
            buttonText={'Teste e nos dê sugestões'}
            onClick={() => openAppPage()}
          />
        </div>
      </div>
      <div
        id='planos'
        className='plans-div'
        style={{
          paddingTop: '8vh',
          paddingBottom: '8vh',
          paddingRight: 16,
          paddingLeft: 16
        }}
      >
        <h2 className='h2' style={{ marginBottom: '6vh' }}>
          Planos
        </h2>
        <div
          style={{
            marginTop: '5vh',
            marginBottom: '5vh',
            paddingRight: 32,
            paddingLeft: 32
          }}
        >
          <p className='p'>
            Encontre o plano <b>mais adequado</b> às suas necessidades:
          </p>
        </div>
        <div style={{ maxWidth: 360, marginLeft: 'auto', marginRight: 'auto' }}>
          <RoundedPrimaryButton
            buttonId='view-plans-button'
            buttonText={'Ver Planos'}
            onClick={() => navigate('/planos')}
          />
        </div>
      </div>
      <DoubtsSection />
      <div
        id='aplicativos'
        className='plans-div'
        style={{
          paddingTop: '8vw',
          paddingBottom: '2vw',
          paddingRight: 16,
          paddingLeft: 16
        }}
      >
        <h2 className='h2' style={{ marginBottom: '6vh' }}>
          Aplicativos
        </h2>
        <div
          className='flex-grid-plans'
          style={{
            paddingBottom: 24,
            flexDirection: 'row',
            flexWrap: 'wrap'
          }}
        >
          <div className='col plan-container'>
            <AppCard
              firstButtonId='app-access-web'
              title={'App Web Petshop'}
              firstButtonText={'Acessar'}
              firstButtonAction={() => openAppPage()}
            />
          </div>
          <div className='col plan-container'>
            <AppCard
              title={'App Cliente'}
              firstButtonId='app-access-android'
              firstButtonText={'Acessar Android'}
              firstButtonAction={() => openClientAppPage()}
              isSecondButtonInactive={true}
              secondButtonText={'Em breve iOS'}
              secondButtonId='app-access-ios'
            />
          </div>
        </div>
      </div>
      {showModal && renderModal()}
      <FooterSection />
    </div>
  )
}

export default PopupTestPage
