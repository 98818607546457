const images = {
  exampleImage: require('../assets/images/app-example-image.png'),

  simpleScheduleIcon: require('../assets/images/simple-schedule-icon.png'),
  shareScheduleIcon: require('../assets/images/share-schedule-icon.png'),
  clientAppIcon: require('../assets/images/client-app-icon.png'),
  contactsIcon: require('../assets/images/contacts-icon.png'),
  whatsappIcon: require('../assets/images/whatsapp-icon.png'),
  financialControlIcon: require('../assets/images/financial-control-icon.png')
}

export default images
